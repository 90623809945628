import React from 'react';
import styled from '@emotion/styled';
import { colors } from 'styles/theme';
import { Box } from 'components';
import { db } from '../services/firebase';

import ChatHandleInput from './ChatHandleInput';
import ChatStatus from './ChatStatus';
import ChatBox from './ChatBox';

const Wrapper = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: justify;
  height: 400px;
  width: 100%;
  max-width: 375px;
`;


class Chat extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      color: null,
      curr_color: 0,
      user_count: 0,
      messages: [],
      readError: null,
      writeError: null,
    };
    this.colors = [ '#00FF00', '#FFF000', '#00FFFF', '#808040',  ];

    this.channel = props.channel
      ? `messages/${props.channel}`
      : 'messages';

    this.handleNameSubmit = this.handleNameSubmit.bind(this);
    this.handleChatSubmit = this.handleChatSubmit.bind(this);

    this.handleChat = this.handleChat.bind(this);
  }

  async componentDidMount() {
    db.ref(this.channel)
      .limitToLast(100)
      .on('child_added', this.handleChat);
  }

  async componentWillUnmount() {
    db.ref(this.channel)
      .off('child_added', this.handleChat);
  }

  async handleChat(msg) {
    console.log('handle chat', msg);

    try {
      const chat = await msg.val();
      chat.self = chat.name === this.state.name;

      this.setState(state => {
        const messages = state.messages.concat(chat);
        return { messages }
      })
    } catch(e) {
      console.log('read error', e.message);
      this.setState({ readError: e.message });
    }

  }

  async handleChatSubmit(msg) {
    // console.log('Chat Submit ::', msg);
    this.setState({ writeError: null });
    try {
      // await db.ref(this.channel).push({
      //   content: msg,
      //   created_at: Date.now(),
      //   name: this.state.name,
      // });

      await db.ref(this.channel).push({
        content: msg,
        created_at: Date.now(),
        name: this.state.name,
        color: this.state.color,
      });
      // await ref.set({
      //     content: msg,
      //     created_at: Date.now(),
      //     name: this.state.name,
      //   });
    } catch(e) {
      console.log('write error', e.message);
      this.setState({ writeError: e.message })
    }
  }

  handleNameSubmit(name) {
    const color = this.colors[Math.floor(Math.random() * this.colors.length)];
    this.setState({ name: name.toLowerCase(), color });
  }

  render() {
    // const {} = this.props;
    const { user_count, name, messages } = this.state;

    // console.log('handle', handle);
    return (
      <Wrapper>
        <ChatStatus users={ user_count } />
        <ChatBox messages={ messages } handle={name} />
        <ChatHandleInput
          onSubmit={name ? this.handleChatSubmit : this.handleNameSubmit}
          placeholder={name ? 'Post a comment' : 'Set Nickname to Join'}
          status={name ? `Posting as ${name}` : '' }
        />
      </Wrapper>
    )
  }
}


export default Chat;