import React from 'react'
import ReactPlayer from 'react-player';
import styled from '@emotion/styled';

const VideoContainer = styled('div')`
  position: relative;
  padding-top: 56.25%;
  width: 100%;
  overflow: hidden;

  border-radius: 32px;
  -webkit-transform: translateZ(0);

  .video-content {
    position: absolute;
    top: 0;
    left: 0;
  }
`;

// const url = 'https://www.youtube.com/watch?v=AhtZQ0uZRlI';

class VideoPlayer extends React.Component {

  render() {
    const {url, loop, muted, controls} = this.props;

    return (
      <VideoContainer>
        <ReactPlayer
          className="video-content"
          url={url}
          playing={true}
          volume={0}
          width="100%"
          height="100%"
          controls={controls || false}

          muted={muted || true}
          playsinline={true}
          config={{
            youtube: {
              playerVars: { disableKb: 1, origin: 'https://dev.b-reel.com' },
              origin: 'https://dev.b-reel.com',
            }
          }}
          loop={loop}
        />
      </VideoContainer>
    );
  }
}

export default VideoPlayer;