import React from 'react';
import styled from '@emotion/styled';
import {post} from 'axios';

import qs from 'qs';

import { RichText } from 'prismic-reactjs';
import Loader from 'components/Loader';

import { Layout, SEO, Grid, Box, Text } from 'components';
import VideoPlayer from 'components/VideoPlayer';

import Input from 'components/events/Input';
import { colors, mediaQueries } from 'styles/theme';

import DeleteIcon from 'images/delete-icon.svg';

import Chat from '../components/events/Chat';

const EventsIntroContainer = styled(Box)`
  width: 100%;
  position: relative;
  background: #fafafa;
  padding: 5% 10%;
  background-color: ${colors.black};
`;

const EventsIntro = styled(Box)`
  display: flex;
  flex-direction: column;
  justify-content: top;
  align-items: center;

  position: sticky;
  top: 0;
  left: 0;

  width: 100%;
  // height: 40vh;

  transition: background-color 0.2s ease-out;

  ${mediaQueries.sm} {
    // height: 60vh;
    min-height: 500px;
  }

  &:not(:first-of-type) {
    border-top: 1px solid ${colors.gray};
  }
`;

const EventsWelcomeContainer = styled(Grid)`
  ${Box} {
    border-bottom: 1px solid ${colors.gray};
  }
`;

const EventsLiveContainer = styled(Grid)`
  .vjs-live {
    border-radius: 32px;
  }
`;

const EventsLiveChat = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const EventsSocialHeader = styled(Text)`
  text-transform: uppercase;
  grid-column: ${['2 / span 10']};
  text-align: center;
  padding-bottom: 35px;
`;

EventsSocialHeader.defaultProps = {
  size: ['1.1x', '0.8x'],
};


const EventsLiveVideo = styled(Box)`
  display: flex;
  flexDirection: column;
  justifyContent: center;
`;


class EventsPage extends React.Component {
  constructor(props) {
    super(props);

    const query = qs.parse(
      this.props.location.search,
      { ignoreQueryPrefix: true },
    );

    this.state = {
      eventEnabled: query.live === 'true',
      formEnabled: false,
      formLoading: false,
      formData: {},
      formErrors: [],
      formSubmitted: false,
    };

    this.onInputUpdate = this.onInputUpdate.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  onInputUpdate(id, val) {
    const {formData} = this.state;

    this.setState({
      formData: {
        ...formData,
        [id]: val,
      },
    });
  }

  checkForm() {
    const { first_name, last_name, email } = this.state.formData;
    const formErrors = [];

    if ( !first_name ) formErrors.push('first_name');
    if ( !last_name ) formErrors.push('last_name');
    if ( !email ) formErrors.push('email');

    this.setState({ formErrors });

    return formErrors;
  }

  // onSubmit() {
  //   console.log('state.formSubmitted', this.state.formData);
  //   if (!this.state.formSubmitted)
  //     this.setState({formSubmitted: true });
  //   else
  //     this.setState({formEnabled: false });
  // }

  async onSubmit() {
    const { first_name, last_name, company, email } = this.state.formData;
    const errors = this.checkForm();

    if ( errors.length > 0) {
      return;
    }

    const url = 'https://api.b-reel.com/v1/events/virtual-event-fenti/rsvps';
    this.setState({formLoading: true });

    await post(url, {
      first_name,
      last_name,
      email,
      metadata: {
        company,
      }
    });

    this.setState({formSubmitted: true, formLoading: false });
  }


  render() {
    // const { data, section } = this.state;

    const metaData = {
      title: RichText.asText([{ text: 'B-Reel — The Download - Internet of Luxury' }]),
      description: RichText.asText([{
        text: "We are proud to introduce Internet of Luxury, a new collaborative project from B-Reel that explores how luxury brands can harness culture and technology to envision new realities in this hyper-digital moment, incorporating proprietary data and expert voices from across the industry.",
      }]),
      image: "https://www.b-reel.com/img/events/2021-january-share.jpg",
      themeColor: '#000'
    };;

    const {
      formEnabled,
      formSubmitted,
      eventEnabled,
      formErrors,
      formLoading,
    } = this.state;

    return (
      <Layout dark>
        <SEO {...metaData} />
        <EventsIntroContainer>
          <EventsIntro textAlign="center">
            <Box pb={[3, 7]} pt="3">
              <Text size={['2x', '3x', '3x']} color={colors.lime} textAlign="center">
                EVENTS
              </Text>
            </Box>
            <EventsLiveContainer>
              <EventsLiveVideo gridColumn={['1 / span 8']} >
                {/* <VideoPlayer url="/media/events-720p.mp4" loop={true} /> */}
                <VideoPlayer
                  muted={false}
                  url="https://youtu.be/uZDcODMEdxs"
                />
              </EventsLiveVideo>
              <EventsLiveChat gridColumn={['span 4']}>
                <Chat channel='virtual-event-internet-of-luxury' />
              </EventsLiveChat>
            </EventsLiveContainer>
          </EventsIntro>
        </EventsIntroContainer>

        <EventsWelcomeContainer>
          <Box gridColumn={['1 / -1', '3 / span 8']} textAlign="left" pt={[3, 5]} pb={[3, 8]} px={[1, 7]}>
            <Text size="0.8x" py="20px">
            {/* Please join us for a talk by B-Reel creative team, Pieter Konickx and Suchi Ahuja, on ‘How to design a flagship experience with no store’. From the team that launched Rihanna's luxury Maison FENTY as a digital first fashion brand, they will be sharing their knowledge on how to build modern fashion brands and revolutionize the shopping experience through design, for an increasingly digital fashion world. */}
            </Text>
          </Box>
        </EventsWelcomeContainer>
      </Layout>
    );
  }
};

export default EventsPage;


