import firebase from 'firebase';

const config = {
  apiKey: 'AIzaSyBepc1ix1CtYG6iQCAPep58BLrUkMaxoY8',
  authDomain: 'brl-website-api-prod.firebaseapp.com',
  databaseURL: 'https://brl-website-api-prod.firebaseio.com',
  projectId: 'brl-website-api-prod',
  appId: '1:1060462264451:web:99e9ef84476ec751eadbb6',
};

firebase.initializeApp(config);

export const auth = firebase.auth;
export const db = firebase.database();

