import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colors } from 'styles/theme';

import { Box } from 'components';

const Handle = styled(Box)`
  text-align: ${(props) => props.align || 'left'};
  color: ${(props) => props.color || '#cccc00'};
  font-size: 0.6em;
`;

const Message = styled(Box)`
  text-align: ${(props) => props.align || 'left'};
  color: ${colors.white};
  font-size: 0.7em;
`;

const Wrapper = styled(Box)`

  position: relative;
  text-align: left;
  margin-left: ${(props) => props.align === 'left' ? '0' : '15px'};
  // margin-right: ${(props) => props.align === 'right' ? '0' : '15px'};
  margin-bottom: 15px;
`;


const ChatMessage = ({ handle, message, align, color }) => {
  return (
    <Wrapper align={align}>
      <Handle align={align} color={color}>{handle}</Handle>
      <Message align={align}>{message}</Message>
    </Wrapper>
  );
}

ChatMessage.defaultProps = {
  align: 'left',
};

ChatMessage.propTypes = {
  handle: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  align: PropTypes.string,
};

export default ChatMessage;