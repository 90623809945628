import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colors } from 'styles/theme';

import { Box } from 'components';

const Title = styled(Box)`
  color: ${colors.white};
  background: none;
  border: none;
`;

const Count = styled(Box)`
  color: ${colors.white};
  position: absolute;
  right: 0;
  font-size: 0.5em;

  padding-right: 10px;
  // flex: 1;
`;

const Wrapper = styled(Box)`
  // position: relative;
  border: 1px solid #333333;
  border-radius: 9999px;
  // display: flex;
  // flex-direction: row;
  // justify-content: center;
  // align-items: center;
  padding: 5px 10px;
  margin-bottom: 10px;
  flex: 0;
`;


const ChatStatus = ({ users }) => {
  return (
    <Wrapper>
      <Title>LIVE</Title>
      {/* <Count>{users} online</Count> */}
    </Wrapper>
  );
}

ChatStatus.propTypes = {
  users: PropTypes.number.isRequired,
};

export default ChatStatus;