import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colors } from 'styles/theme';

import { Box } from 'components';

const HandleField = styled('input')`
  color: ${colors.white};
  background: none;
  border: none;
  flex: 1;

  &:focus {
    outline: none;
  }
`;

const HandleButton = styled(Box)`
  color: ${colors.whiteDimmed01};
  // flex: 1;
`;

const Wrapper = styled(Box)`
  border: 1px solid #333333;
  border-radius: 9999px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px 10px;
  flex: 0;
`;

const StatusWrapper = styled(Box)`
  color: ${(props) => props.color || colors.white};
  padding: 5px;
  font-size: 0.6em;
`;

class ChatHandleBox extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      focused: false,
    };

    this.onChange = this.onChange.bind(this);
    // this.onFocus = this.onFocus.bind(this);
    // this.onBlur = this.onBlur.bind(this);
    this.onKeyPress = this.onKeyPress.bind(this);
    this.onSubmit = this.onSubmit.bind(this);
  }

  componentWillMount() {
    // window.removeEventListener( 'keypress', this.onKeyPress);
  }

  onChange(evt) {
    const { value } = evt.target;
    this.setState({ value });
  }

  onKeyPress( evt ) {
    const { key } = evt;
    // console.log('Keypressed', evt);


    if ( key === 'Enter' ) {
      this.onSubmit();
    }
  }

  onFocus() {
    console.log('focus', this.state.focused);
    window.addEventListener( 'keypress', this.onKeyPress);
    this.setState({ focused: true });
  }

  onBlur() {
    console.log('blur', this.state.focused);
    window.removeEventListener( 'keypress', this.onKeyPress);
    this.setState({ focused: false });
  }

  onSubmit() {
    const { value } = this.state;

    this.props.onSubmit(value);
    this.setState({ value: '' });

  }

  render() {
    const { error, placeholder, status } = this.props;
    const { value } = this.state;
    return (
      <Box style={{ paddingTop: (status === null ? '0px' : '10px') }}>
        { status
          ? <StatusWrapper textAlign="left" color={colors.whiteDimmed01}>
              {status}
            </StatusWrapper>
          : null
        }
        <Wrapper>
          <HandleField
            name="handle"
            value={value}
            placeholder={ placeholder || 'Set Nickname to Join' }
            onKeyUp={this.onKeyPress}
            onChange={this.onChange}
            // onFocus={this.onFocus}
            // onBlur={this.onBlur}
          />
          <HandleButton onClick={this.onSubmit}>↵</HandleButton>
        </Wrapper>
        { error
          ? <StatusWrapper color={colors.red}>
              {error}
            </StatusWrapper>
          : null
        }
      </Box>
    );
  }
}

ChatHandleBox.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  status: PropTypes.string,
  error: PropTypes.string,
};

export default ChatHandleBox;