import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { colors } from 'styles/theme';

import { Box } from 'components';

import { animateScroll } from 'react-scroll';

import ChatMessage from './ChatMessage';

const Title = styled(Box)`
  color: ${colors.white};
  background: none;
  border: none;
`;

const Count = styled(Box)`
  color: ${colors.white};
  position: absolute;
  right: 0;
  font-size: 0.5em;

  padding-right: 10px;
  flex: 1;
`;

const Wrapper = styled(Box)`
  position: relative;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    display: none;
  }
  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
  overflow-x: hidden;
  border: 1px solid #333333;
  border-radius: 32px;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 0 0 10px 0;
  text-align: left;
  // padding: 20px 10px 0 10px;
  flex: 1;
`;

const Content = styled(Box)`
  // overflow: scroll;
  margin: 15px 15px 10px 15px;
`;

const NameInput = styled(Box)`
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  background-color: rgba(0, 0, 0, 0.7);
  color: #ffffff;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
`;


const ChatBox = ({ messages, handle }) => {
  const [currMessages, setCurrMessages] = useState([]);
  const [showNickName, setShowNickName] = useState(false);

  const scrollToBottom = () => {
    animateScroll.scrollToBottom({ containerId: 'chatBox-wrapper', duration: 200 });
  }

  useEffect(() => {
    setCurrMessages(messages);
    scrollToBottom();
  });

  // console.log('messages', messages);
  // messages.forEach( msgTest => console.log('msg test', msgTest.message ) );
  return (
    <Wrapper id="chatBox-wrapper">
      {/* <NameInput>
        Test Input
      </NameInput> */}
      <Content>
        {
          currMessages.map((msg, index) => (
            <ChatMessage
              key={`chat-${index}`}
              handle={msg.name}
              align={ msg.self ? 'right' : 'left'}
              message={msg.content}
              color={msg.color}
            />
          ))
        }
        {/*
        <ChatMessage handle='User 1' message="Dolor mollit ipsum ea elit incididunt sint. Aute adipisicing ullamco enim." />
        <ChatMessage handle='User 1' message="Dolor mollit ipsum ea elit incididunt sint. Aute adipisicing ullamco enim." />
        */}
      </Content>
    </Wrapper>
  );
}

ChatBox.propTypes = {
  messages: PropTypes.array,
  handle: PropTypes.string,
};

export default ChatBox;